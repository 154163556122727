import S1 from "../assets/S1.jpg"
import S2 from "../assets/S2.jpg"
import S3 from "../assets/S3.jpg"
import S4 from "../assets/S4.jpg"
import S5 from "../assets/S5.jpg"
import S6 from "../assets/S6.jpg"
import S7 from "../assets/S7.jpg"
import S8 from "../assets/S8.jpg"
import S9 from "../assets/S9.jpg"

function Services() {
  const services = [
    {
      title: "ALUMINIUM KINETIC FACADE CLADDING",
      image: S1,
      description:
        "Our Aluminium Kinetic Facade is a popular material of modern architecture, offering dynamic movement and energy efficiency...",
    },
    {
      title: "HALF WAVE KINETIC SYSTEMS",
      image: S2,
      description:
        "Our Half wave Kinetic systems provide beautiful aesthetic and responsive movement to environmental conditions...",
    },
    {
      title: "FULL WAVE KINETIC FACADE",
      image: S3,
      description:
        "As a Full wave Kinetic Facade manufacturer, we provide galvalume, aluminum, zinc, copper, and stainless steel solutions...",
    },
    {
      title: "KINETIC GLAZING SOLUTIONS",
      image: S4,
      description:
        "Our Kinetic Glazing solutions feature large glass panels that are dynamic and responsive to environmental changes...",
    },
    {
      title: "PARAMETRIC FACADE SYSTEMS",
      image: S5,
      description:
        "As a Parametric Facade manufacturer, we create skylight glazing systems that adapt to changing light conditions...",
    },
    {
      title: "KINETIC ROPE FACADE SYSTEM",
      image: S6,
      description:
        "Our Kinetic Rope Facade system typically incorporates a fabric, glass or metal covering with dynamic movement capabilities...",
    },
    {
      title: "DYNAMIC FACADE SOLUTIONS",
      image: S7,
      description:
        "As a Dynamic Facade manufacturer, we create popular material combinations for modern architecture with responsive elements...",
    },
    {
      title: "STATIC FACADE SYSTEMS",
      image: S8,
      description:
        "Our Static Facade manufacturer services provide beautiful aesthetic and a wide range of design possibilities...",
    },
    {
      title: "AUTOMATED KINETIC FACADES",
      image: S9,
      description:
        "Our Automated Kinetic Facades are made from a unique blend of natural materials with motorized components for responsive movement...",
    },
    // Add more services here
  ]

  return (
    <section className="services-section py-5">
      <div className="container">
        <div className="section-header text-center mb-5">
          <h2 className="fw-bold">KINETIC FACADES SERVICES</h2>
          <p>Specialized in Dynamic and Static Facade Manufacturing</p>
        </div>
        <div className="row g-4">
          {services.map((service, index) => (
            <div key={index} className="col-md-4">
              <div className="card h-100">
                <img src={service.image || "/placeholder.svg"} className="card-img-top" alt={service.title} />
                <div className="card-body">
                  <h5 className="card-title">{service.title}</h5>
                  <p className="card-text">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Hidden SEO Keywords */}
        <div className="d-none">
          <p>
            Kinetic Facades manufacturer, Dynamic Facade manufacturer, Static Facade manufacturer, Kinetic elevation,
            Kinetic Glazing, Kinetic marble elevation, Parametric Facade manufacturer, Kinetic louvers system, Half wave
            Kinetic systems, Full wave Kinetic Facade manufacturer, Kinetic Rope Facade system manufacturer, Vertical
            Rotation Kinetic Facade manufacturer, Kinetic fixed Facade manufacturer, Rose gold Kinetic Facade
            manufacturer, 4 wave Kinetic louvers system manufacturer, Automated Kinetic Facades manufacturer
          </p>
        </div>
      </div>
    </section>
  )
}

export default Services

