import { useState } from "react"
import { Link } from "react-router-dom"
import Logo from "../assets/Logo.png"
import Modal from "react-modal" // Import the modal component
import "../App.css"
// Make sure to bind modal to your appElement
Modal.setAppElement("#root") // Assuming your app's root element has the id 'root'

function Footer() {
  // State for each modal
  const [isConceptModalOpen, setIsConceptModalOpen] = useState(false)
  const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState(false)

  // Define the content for each modal
  const conceptContent = (
    <div>
      <h2 style={{ fontSize: "24px", marginBottom: "20px", color: "#333",backgroundColor:"g", textAlign:'center' }}>The Concept of Kinetic Façades</h2>
      <p style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}>
        <li>        A kinetic façade is a type of building envelope that features movable elements. Unlike traditional static
        façades, kinetic façades are designed to respond dynamically to various stimuli, such as sunlight, temperature,
        wind, or human interaction. The movement can be mechanical, hydraulic, or based on smart materials that react to
        environmental changes.
        </li>
      </p>
      <p style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}>
        <li>
        The idea behind kinetic façades is to create a responsive building skin that can adapt to optimise indoor
        comfort, reduce energy consumption, and enhance the building’s aesthetic appeal. This adaptability makes kinetic
        façades particularly useful in climates with varying weather conditions or in buildings aiming for high
        sustainability standards.
        </li>
      </p>
      
    </div>
  )

  const benefitsContent = (
    <div>
      <h2 style={{ fontSize: "24px", marginBottom: "20px", color: "#333",textAlign:'center' }}>Benefits of Kinetic Façades</h2>
      <ul style={{ fontSize: "16px", lineHeight: "1.6", color: "#555", paddingLeft: "20px" }}>
        <li style={{ marginBottom: "10px" }}>
          <strong>Energy Efficiency:</strong> One of the primary benefits of kinetic façades is their ability to enhance
          a building’s energy efficiency. By adjusting to control sunlight and shade, kinetic façades can reduce the
          need for artificial lighting and air conditioning. This dynamic control over the building’s thermal
          environment can lead to significant energy savings, making kinetic façades an attractive option for
          sustainable architecture.
        </li>
        <li style={{ marginBottom: "10px" }}>
          <strong>Aesthetic Appeal:</strong> Kinetic façades offer a unique aesthetic dimension to buildings. The
          movement of façade elements can create patterns, shadows, and reflections that change throughout the day,
          adding a dynamic visual element to the building’s exterior. This can make the building a landmark and a piece
          of public art, attracting attention and interest.
        </li>
        <li style={{ marginBottom: "10px" }}>
          <strong>Adaptability:</strong> Kinetic façades can adapt to various environmental conditions, providing
          optimal comfort for building occupants. For instance, during hot weather, the façade can provide maximum
          shading while allowing more sunlight to enter the building in cooler conditions. This adaptability makes
          kinetic façades suitable for various climates and applications.
        </li>
        <li style={{ marginBottom: "10px" }}>
          <strong>Versatility in Glass Selection:</strong> Kinetic façades offer flexibility in allowing architects to
          choose from a wide range of glass types that align with their design concepts and creativity, including
          tinted, clear, and low-iron glass. The dynamic shading devices incorporated in kinetic façades can respond to
          varying sunlight and weather conditions, providing effective solar protection and maintaining comfortable
          indoor environments. This feature enables the use of highly transparent glass, which typically allows more
          light and heat to pass through, without compromising occupant comfort or the building’s energy efficiency.
        </li>
        <li style={{ marginBottom: "10px" }}>
          <strong>Enhanced User Experience:</strong> Interactive kinetic façades offer an engaging user experience,
          allowing individuals to interact with the building’s exterior. This interaction can be educational, artistic,
          or simply entertaining, adding a layer of engagement that static façades cannot offer.
        </li>
      </ul>
     
    </div>
  )

  return (
    <footer className="footer-section">
      <div className="container pt-3">
        <div className="footer-logo mb-4">
          <img
            src={Logo || "/placeholder.svg"}
            alt="Global Innovation"
            className="img-fluid"
            style={{ height: "180px", width: "280px" }}
          />
        </div>

        <div className="row g-4">
          <div className="col-lg-3 col-md-6 " style={{textAlign:'justify'}}>
            <h4 className="text-white mb-4">About</h4>
            <p className="text-light mb-4">
              <li>
                Kinetic façades represent a groundbreaking evolution in architectural design, merging aesthetics with
                functionality. These dynamic building exteriors can change in response to environmental conditions,
                enhancing energy efficiency and creating visually stunning structures. This article explores the
                concept, benefits, and future potential of kinetic façades, highlighting how they are revolutionising
                the field of architecture.
              </li>
              <li className="clickable" onClick={() => setIsBenefitsModalOpen(true)}>
  Benefits of Kinetic façades <span style={{ color: "yellow" }}> more...</span>
</li>
<li className="clickable" onClick={() => setIsConceptModalOpen(true)}>
  The concept of kinetic façades <span style={{ color: "yellow" }}> more...</span>
</li>

            </p>
            <Link to="/about" className="read-more text-warning text-decoration-none">
              Read More
            </Link>
          </div>

          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Services</h4>
            <ul className="list-unstyled footer-links">
              <li>
                <Link to="/services">Shop Glazing, ACP And Stick Glazing With Openable Window</Link>
              </li>
              <li>
                <Link to="/services">Aluminum Composite Panels(Cladding) With Glazing</Link>
              </li>
              <li>
                <Link to="/services">Spider Glazing</Link>
              </li>
              <li>
                <Link to="/services">Patch Fitting Glass Doors</Link>
              </li>
              <li>
                <Link to="/services">Laminated Glass Flooring</Link>
              </li>
              
              <li>
                <Link to="/services">Aluminum Partition</Link>
              </li>
              <li>
                <Link to="/services">Shop Glazzing</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Quick Links</h4>
            <ul className="list-unstyled footer-links">
              <li>
                <Link to="/products">Aluminum Windows - Single / Double Glazed</Link>
              </li>
              <li>
                <Link to="/products">Sliding / Openable / Fixed</Link>
              </li>
              <li>
                <Link to="/products">Sky Light</Link>
              </li>
              <li>
                <Link to="/products">Canopy (Glass / APC / Spider)</Link>
              </li>
              <li>
                <Link to="/products">Room Interior With ACP</Link>
              </li>
              <li>
                <Link to="/products">Louvers</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Get in touch</h4>
            <div className="contact-info text-light">
              <p>
                No. 6 Anna 1st Street, Padi,
                <br />
                Chennai - 600 050. (T.N).
              </p>
              <p className="mb-2">
                <a href="tel:+919962295952" className="text-light text-decoration-none">
                  +91 9962295952
                </a>
              </p>
              <p className="mb-2">
                <a href="tel:+918056168143" className="text-light text-decoration-none">
                  +91 8056168143
                </a>
              </p>
              <p className="mb-2">
                <a href="mailto:shiva@globalinnovation.in" className="text-light text-decoration-none">
                  shiva@globalinnovation.in
                </a>
              </p>
              <p className="mb-2">
                <a href="mailto:globalglazing@gmail.com" className="text-light text-decoration-none">
                  globalinnovation2007@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="footer-bottom mt-5 pt-4 pb-3 border-top border-secondary">
          <div className="row">
            <div className="col-md-6">
              <p className="mb-0 text-light">Global Innovation</p>
            </div>
            <div className="col-md-6 text-end">
              <Link to="/privacy-policy" className="text-light text-decoration-none">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isConceptModalOpen}
        onRequestClose={() => setIsConceptModalOpen(false)}
        contentLabel="Concept Modal"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          content: {
            padding: 0,
            border: "none",
            background: "white",
            borderRadius: "10px",
            overflow: "hidden",
          },
        }}
      >
        <div className="modal-footer">
          
        </div>
        <div className="modal-content">{conceptContent}</div>
        <button onClick={() => setIsConceptModalOpen(false)} className="modal-close-button w-100">
            Close
          </button>
        <div className="modal-footer">
          
        </div>
        
      </Modal>

      <Modal
        isOpen={isBenefitsModalOpen}
        onRequestClose={() => setIsBenefitsModalOpen(false)}
        contentLabel="Benefits Modal"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          content: {
            padding: 0,
            border: "none",
            background: "white",
            borderRadius: "10px",
            overflow: "hidden",
          },
        }}
      >
        <div className="modal-footer">
        
          </div>
        <div className="modal-content">{benefitsContent}</div>
        <button onClick={() => setIsBenefitsModalOpen(false)} className="modal-close-button w-100" >
          Close
          </button>
        <div className="modal-footer">
        
        </div>
      
      </Modal>
    </footer>
  )
}

export default Footer

