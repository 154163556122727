import React, { useState } from "react"
import { MapPin, Phone, Mail, RefreshCw } from "lucide-react"
import {Link} from 'react-router-dom';
export default function ContactPage() {
  const [captchaNum1] = useState(4)
  const [captchaNum2] = useState(5)
  const [userCaptcha, setUserCaptcha] = useState("")
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (Number.parseInt(userCaptcha) === captchaNum1 + captchaNum2) {
      console.log("Form submitted:", formData)
      // Handle form submission
    } else {
      alert("Invalid captcha")
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <div className="min-vh-100 d-flex flex-column">
      {/* Hero Section */}
      <div
        className="hero-section position-relative py-3"
        style={{
          backgroundImage: 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))',
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "250px", // Set height to 200px
        }}
      >
        <div className="container text-center text-white py-3">
          <h1 className="display-4 fw-bold mb-3 text-center text-light">CONTACT US</h1>
          <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Testimonials
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Contact Info Section */}
      <div className="contact-info py-5" style={{ backgroundColor: "#ff7f50" }}>
        <div className="container px-4 px-lg-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8 px-lg-5">
              <h2 className="text-center text-white mb-5">GET IN TOUCH WITH US</h2>
              <div className="row g-4 justify-content-center px-md-4">
                <div className="col-md-4 px-md-4">
                  <div className="d-flex align-items-center text-white">
                    <div className="contact-icon">
                      <MapPin size={24} />
                    </div>
                    <div className="ms-3">
                      <p className="mb-0">No. 6 Anna 1st Street, Padi, Chennai - 600 050. (T.N).</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 px-md-4">
                  <div className="d-flex align-items-center text-white">
                    <div className="contact-icon">
                      <Phone size={24} />
                    </div>
                    <div className="ms-3">
                      <p className="mb-0">+91 80561 68143</p>
                      <p className="mb-0">+91 99622 95952</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 px-md-4">
                  <div className="d-flex align-items-center text-white">
                    <div className="contact-icon">
                      <Mail size={24} />
                    </div>
                    <div className="ms-3">
                      <p className="mb-0">shiva@globalinnovation.in</p>
                      <p className="mb-0">globalinnovation2007@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="text-center mb-4">
              <h2 className="text-danger">Send us a message</h2>
              <p className="text-muted">We'll be happy to provide our assistance to clear out any of your queries.</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control bg-light"
                    placeholder="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="tel"
                    className="form-control bg-light"
                    placeholder="Your Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control bg-light"
                    placeholder="Your Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control bg-light"
                    rows="5"
                    placeholder="Write your comment here..."
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <div className="col-12">
                  <div className="captcha-container d-flex align-items-center gap-2">
                    <div className="captcha-box bg-success text-white px-3 py-2">{captchaNum1}</div>
                    <div className="captcha-box bg-success text-white px-3 py-2">+</div>
                    <div className="captcha-box bg-success text-white px-3 py-2">{captchaNum2}</div>
                    <button type="button" className="btn btn-success" onClick={() => setUserCaptcha("")}>
                      <RefreshCw size={20} />
                    </button>
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control bg-light"
                    placeholder="Enter the captcha"
                    value={userCaptcha}
                    onChange={(e) => setUserCaptcha(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-danger px-4 py-2">
                    SUBMIT
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        .contact-icon {
          width: 40px;
          height: 40px;
          background: rgba(255, 255, 255, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        
        .form-control {
          border: 1px solid #dee2e6;
          padding: 0.75rem;
        }
        
        .form-control:focus {
          box-shadow: none;
          border-color: #ff7f50;
        }
        
        .captcha-box {
          border-radius: 4px;
          font-size: 1.2rem;
          font-weight: bold;
        }
      `}</style>
    </div>
  )
}

