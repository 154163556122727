import { Link } from "react-router-dom"

export default function SEOLandingPage() {
  const kineticFacadeTypes = [
    {
      title: "Half Wave Kinetic Systems",
      description:
        "Our Half wave Kinetic systems provide dynamic movement with a partial wave pattern, creating stunning visual effects while optimizing energy efficiency.",
    },
    {
      title: "Full Wave Kinetic Facade",
      description:
        "As a Full wave Kinetic Facade manufacturer, we create complete wave patterns that respond to environmental conditions, providing both aesthetic appeal and functional benefits.",
    },
    {
      title: "Kinetic Rope Facade System",
      description:
        "Our Kinetic Rope Facade system utilizes innovative cable and pulley mechanisms to create dynamic movement in building facades, offering both aesthetic appeal and practical shading solutions.",
    },
    {
      title: "Vertical Rotation Kinetic Facade",
      description:
        "As a Vertical Rotation Kinetic Facade manufacturer, we design systems with vertically rotating elements that respond to sunlight and environmental conditions, optimizing energy efficiency.",
    },
    {
      title: "Kinetic Fixed Facade",
      description:
        "Our Kinetic fixed Facade solutions combine the stability of fixed elements with dynamic components that can adapt to changing environmental conditions.",
    },
    {
      title: "Aluminium Kinetic Facade",
      description:
        "As an Aluminium Kinetic Facade manufacturer, we create lightweight, durable, and corrosion-resistant dynamic facade systems that are both functional and visually striking.",
    },
    {
      title: "4 Wave Kinetic Louvers System",
      description:
        "Our 4 wave Kinetic louvers system manufacturer expertise allows us to create complex wave patterns that provide optimal shading while maintaining aesthetic appeal.",
    },
    {
      title: "Automated Kinetic Facades",
      description:
        "Our Automated Kinetic Facades use smart technology to respond automatically to environmental conditions, optimizing energy efficiency and occupant comfort.",
    },
    {
      title: "Premium Facade Manufacturer",
      description:
        "We design and manufacture innovative, high-quality facades that enhance aesthetics, energy efficiency, and durability, creating modern and sustainable building solutions..",
    },
  ]

  return (
    <div className="min-vh-100 bg-light">
      {/* Hero Section */}
      <div
        className="position-relative py-5"
        style={{
          backgroundImage: "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8))",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container py-5 text-center text-white">
          <h1 className="display-3 fw-bold mb-4">KINETIC FACADES MANUFACTURER</h1>
          <p className="lead mx-auto" style={{ maxWidth: "800px" }}>
            Global Innovation is a leading Kinetic Facades manufacturer specializing in Dynamic Facade, Static Facade,
            Parametric Facade, and innovative architectural solutions for modern buildings.
          </p>
          <div className="mt-4">
            <Link to="/contact" className="btn btn-warning btn-lg px-4 me-2">
              Contact Us
            </Link>
            <Link to="/services" className="btn btn-outline-light btn-lg px-4">
              Our Services
            </Link>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container py-5">
        <div className="row mb-5">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="h1 mb-4">Innovative Kinetic Facade Solutions</h2>
            <p className="lead">
              As a premier Kinetic Facades manufacturer, we specialize in creating dynamic, responsive building
              envelopes that adapt to environmental conditions, optimize energy efficiency, and create stunning
              architectural statements.
            </p>
          </div>
        </div>

        {/* Types of Kinetic Facades */}
        <div className="row mb-5">
          <div className="col-12 text-center mb-4">
            <h2>Our Kinetic Facade Solutions</h2>
            <p>Explore our range of dynamic and static facade systems</p>
          </div>

          {kineticFacadeTypes.map((type, index) => (
            <div key={index} className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body">
                  <h3 className="h5 card-title">{type.title}</h3>
                  <p className="card-text"> {type.description}</p>
                </div>
                <div className="card-footer bg-transparent border-top-0">
                  <Link to="/services" className="btn btn-sm btn-outline-primary">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Benefits Section */}
        <div className="row py-5 bg-light">
          <div className="col-lg-8 mx-auto text-center mb-5">
            <h2>Benefits of Kinetic Facades</h2>
            <p className="lead">Why choose our dynamic facade solutions for your next project</p>
          </div>

          <div className="col-md-4 mb-4">
            <div className="text-center">
              <div className="mb-3">
                <span className="display-4 text-warning">🌞</span>
              </div>
              <h3 className="h4">Energy Efficiency</h3>
              <p>Our Kinetic Facades adapt to optimize natural light and reduce energy consumption by up to 30%.</p>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="text-center">
              <div className="mb-3">
                <span className="display-4 text-warning">🏢</span>
              </div>
              <h3 className="h4">Aesthetic Appeal</h3>
              <p>
                Create stunning, dynamic building exteriors that change throughout the day, becoming architectural
                landmarks.
              </p>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="text-center">
              <div className="mb-3">
                <span className="display-4 text-warning">🌡️</span>
              </div>
              <h3 className="h4">Occupant Comfort</h3>
              <p>Maintain optimal indoor conditions by automatically adjusting to changing environmental factors.</p>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="row py-5">
          <div className="col-lg-10 mx-auto text-center">
            <h2 className="mb-4">Ready to Transform Your Building?</h2>
            <p className="lead mb-4">
              Contact Global Innovation today to discuss your Kinetic Facade project. As a leading Dynamic Facade
              manufacturer, we provide customized solutions for any architectural vision.
            </p>
            <Link to="/contact" className="btn btn-warning btn-lg px-5 py-3">
              Get a Free Consultation
            </Link>
          </div>
        </div>
      </div>

      {/* Hidden SEO Keywords */}
      <div className="d-none">
        <p>
          Kinetic Facades manufacturer, Dynamic Facade manufacturer, Static Facade manufacturer, Kinetic elevation,
          Kinetic Glazing, Kinetic marble elevation, Parametric Facade manufacturer, Kinetic louvers system, Half wave
          Kinetic systems, Full wave Kinetic Facade manufacturer, Kinetic Rope Facade system manufacturer, Vertical
          Rotation Kinetic Facade manufacturer, Kinetic fixed Facade manufacturer, Rose gold Kinetic Facade
          manufacturer, 4 wave Kinetic louvers system manufacturer, Automated Kinetic Facades manufacturer, Kinetic
          Facades perforation, Aluminium Kinetic Facade manufacturer, Kinetic elevation Facade manufacturer, Kinetic
          fluted Facade manufacturer, Kinetic Facades motorized, Kinetic Facades ss mirror, Kinetic Facades embossed
          panel, Parametric ss Facade cascading elevation, Transparent Kinetic Facade solid panel manufacturer, Kinetic
          Facade semi unitised structural Glazing structure system, Kinetic Facades types all in one, Kinetic Facades
          glasses system manufacturer, Glass elevation Kinetic Facades manufacturer, Kinetic Facades cladding, Kinetic
          innovation Facades manufacturer, Kinetic elevation cladding, Kinetic wall cladding, Kinetic wall wind driven
          Facade manufacturer, Kinetic Facades design innovation system manufacturer, Kinetic Facades building materials
          manufacturer, Kinetic elevation product Facade service provider, Kinetic interior ss mirror elevation product
          manufacturer, Stainless steel Kinetic Facades manufacturer system, Dynamic 4frame Facade manufacturer, Static
          - Kinetic Facades manufacturer, Kinetic Facades Artificial movement system manufacturer, Kinetic Facades
          natural wind driven system manufacturer, Kinetic sustainable elevation product manufacturer, Kinetic Facades
          architecture, Glazing structure Kinetic Facades manufacturer, Kinetic Facades engineering product
          manufacturer, Facade works, Kinetic Aluminium fins, Corten steel Kinetic Facades manufacturer, Static Facade
          Glazing structure system manufacturer, Kinetic HPL cladding, Parametric Facade system manufacturer, Parametric
          ss mirror elevation,Premium Facade Manufacturer,Luxury façades Manufacturer,  
Metal façades,  
Kinetic Horizontal pivot façades shading devices system,  
Kinetic vertical pivot façades shading devices system,  
Kinetic Horizontal foldings façades shading system,  
Shading,  
Premium kinetic ss façades Manufacturer,  
Premium metal façades Manufacturer,  
High quality Commercial kinetic façades Manufacturer in India,  
 Kinetic wind energy Facade manufacturer, Dynamic elements Facade manufacturer, Kinetic
          Facades designing product manufacturer
        </p>
      </div>
    </div>
  )
}

