import React, { useEffect, useState } from "react"

function Stats() {
  const [isVisible, setIsVisible] = useState(false)

  const stats = [
    {
      number: 35185,
      suffix: "+",
      title: "SQ. FT. STATE-OF-THE-ART SET-UP",
    },
    {
      number: 100,
      suffix: "+",
      title: "FEEDBACK RECEIVED",
    },
    {
      number: 14,
      suffix: "+",
      title: "YEARS OF SPECIALISED",
    },
    {
      number: 100,
      suffix: "+",
      title: "PRODUCTION CAPACITY FROM FACTORIES",
    },
    {
      number: 200,
      suffix: "+",
      title: "WORK DONE TILL DATE",
    },
  ]

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("stats-section")
      if (element) {
        const position = element.getBoundingClientRect()
        if (position.top < window.innerHeight && position.bottom >= 0) {
          setIsVisible(true)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    handleScroll() // Check on initial load

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const Counter = ({ number, suffix, title }) => {
    const [count, setCount] = useState(0)

    useEffect(() => {
      if (isVisible) {
        let start = 0
        const end = number
        const duration = 2000 // 2 seconds
        const increment = end / (duration / 16) // 60fps

        const timer = setInterval(() => {
          start += increment
          if (start > end) {
            setCount(end)
            clearInterval(timer)
          } else {
            setCount(Math.floor(start))
          }
        }, 16)

        return () => clearInterval(timer)
      }
    }, [isVisible, number])

    return (
      <div className="stat-item text-center mb-4">
        <h2 className="display-4 fw-bold text-white mb-2">
          {count}
          {suffix}
        </h2>
        <p className="text-warning text-uppercase small mb-0">{title}</p>
      </div>
    )
  }

  return (
    <>
      <div className="hero-stats py-5" style={{ backgroundColor: "#ff7f50" }}>
        <div className="container text-center text-white py-4">
          <h1 className="display-5 fw-bold mb-3">Global Innovation Dedicated Duty</h1>
          <h2 className="h3 mb-4">NO QUALITY COMPROMISE</h2>
          <p className="lead px-md-5 mx-md-5">
            Since our Inception,our mission has been to achieve complete Client Satisfaction by providing them with the
            products of their choice at reasonable rates.We are oriented towards providing dedicated services to our end
            users and maintain quality in all our dealings.
          </p>
        </div>
      </div>

      <div id="stats-section" className="stats-section py-5" style={{ background: "rgba(0,0,0,0.8)" }}>
        <div className="container">
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5 g-4">
            {stats.map((stat, index) => (
              <div key={index} className="col">
                <Counter {...stat} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Stats

