import React from "react"
import {Link} from 'react-router-dom';
import cp1 from '../assets/cp1.png'
import cp2 from '../assets/cp2.jpg'
import cp3 from '../assets/cp3.jpg'
import cp4 from '../assets/cp4.png'
import cp5 from '../assets/cp5.png'
import cp6 from '../assets/cp6.png'
import cp7 from '../assets/cp7.png'
import cp8 from '../assets/cp8.jpeg'
import cp9 from '../assets/cp9.png'
import cp10 from '../assets/cp10.jpg'
import cp11 from '../assets/cp11.jpeg'
import cp12 from '../assets/cp12.jpg'
import cp13 from '../assets/cp13.jpg'
import cp14 from '../assets/cp14.jpg' 
import cp15 from '../assets/cp15.jpg'
import cp16 from '../assets/cp16.jpg'
import cp17 from '../assets/cp17.png'
import cp18 from '../assets/cp18.jpeg'
import cp19 from '../assets/cp19.jpg'
import cp20 from '../assets/cp20.jpg'
import cp21 from '../assets/cp21.png'
import cp22 from '../assets/cp22.jpg'
import cp23 from '../assets/cp23.png'
import cp24 from '../assets/cp24.png'
import cp25 from '../assets/cp25.png'
const clintLogos = [
  // First Grid Section
  {
    title: "OUR HONORABLE CLIENTS",
    clients: [
      {
        src:cp1,
        alt: "TSO Design",
      },
      {
        src: cp2,
        alt: "TVS",
      },
      {
        src: cp3,
        alt: "Modine",
      },
      {
        src:cp4,
        alt: "Global Remote",
      },
      {
        src: cp5,
        alt: "Radix Designs",
      },
    ],
  },
  {
    title: "",
    clients: [
      {
        src: cp6,
        alt: "Kalyani",
      },
      {
        src: cp7,
        alt: "Circuits 99",
      },
      {
        src:cp8,
        alt: "Sree Sastha",
      },
      {
        src: cp9,
        alt: "Janus Global School",
      },
      {
        src:cp10,
        alt: "Green Grid Group",
      },
    ],
  },
  {
    title: "",
    clients: [
      {
        src:cp11,
        alt: "India Builders",
      },
      {
        src: cp12,
        alt: "Raaga",
      },
      {
        src: cp13,
        alt: "Taj Clubhouse",
      },
      {
        src: cp14,
        alt: "VIT",
      },
      {
        src: cp15,
        alt: "VIS",
      },
    ],
  },
  {
    title: "",
    clients: [
      {
        src:cp16,
        alt: "India Builders",
      },
      {
        src: cp17,
        alt: "Raaga",
      },
      {
        src: cp18,
        alt: "Taj Clubhouse",
      },
      {
        src: cp19,
        alt: "VIT",
      },
      {
        src: cp20,
        alt: "VIS",
      },
    ],
  },
  {
    title: "",
    clients: [
      {
        src:cp21,
        alt: "India Builders",
      },
      {
        src: cp22,
        alt: "Raaga",
      },
      {
        src: cp23,
        alt: "Taj Clubhouse",
      },
      {
        src: cp24,
        alt: "VIT",
      },
      {
        src: cp25,
        alt: "VIS",
      },
    ],
  },
]

const otherClientsList = [
  {
    title: "Other Clients",
    clients: [
      "TSO DESIGN COMMUNE PVT. LTD.",
      "TVS HOSUR MAIN PLANT",
      "PRACHIDHI SPINNERS PVT. LTD.",
      "MX VINSUN AGENCIES",
      "ASCAR & PONNI",
    ],
  },
  {
    title: "",
    clients: [
      "CORNERSTONE(P) LTD.",
      "SEVERN GLOCON INDIA(P) LTD.",
      "SASTHA GROUP OF INSTITUTION",
      "VIVEKS LTD.",
      "NEOMED HOSPITALS",
    ],
  },
  {
    title: "",
    clients: [
      "INDIA BUILDERS",
      "SRI SAI CONSTRUCTORS",
      "SYNDICATE WIPER PVT. LTD.",
      "G.R. ESTATES",
      "AROTECH CORIEN COMPANY",
    ],
  },
  {
    title: "",
    clients: [
      "VOLTECH ENGINEERS(P) LTD",
      "CHANDHAR ASSOCIATES",
      "SAHANA'S CREATIONS",
      "RAGA FOUNDATION(P) LTD",
      "KCC CONSTRUCTIONS",
    ],
  },
]

export default function ClientsPage() {
  return (
    <div className="min-vh-100 d-flex flex-column bg-light">
      {/* Hero Section */}
      <div
  className="hero-section position-relative py-3"
  style={{
    backgroundImage: 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))',
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "250px", // Set height to 200px
  }}
>
  <div className="container text-center text-white py-3">
    <h1 className="display-4 fw-bold mb-3 text-center text-light">OUR HONORABLE CLIENTS</h1>
    <nav aria-label="breadcrumb">
    <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Client
              </li>
            </ol>
    </nav>
  </div>
</div>


      {/* Client Logo Grids */}
      <div className="container py-5 ">
        {clintLogos.map((section, sectionIndex) => (
          <div key={sectionIndex} className="mb-5">
            {section.title && <h2 className="text-center mb-4">{section.title}</h2>}
            <div className="row g-8">
              {section.clients.map((client, index) => (
                <div key={index} className="col-6 col-sm-4 col-lg-2-4">
                  <div className="card h-100 border client-card">
                    <div className="card-body d-flex align-items-center justify-content-center ">
                      <img src={client.src || "/placeholder.svg"} alt={client.alt} 
                       className="img-fluid client-logo" />
                       
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Other Clients Text Grids */}
        {otherClientsList.map((section, sectionIndex) => (
          <div key={sectionIndex} className="mb-5">
            {section.title && <h2 className="text-center mb-4">{section.title}</h2>}
            <div className="row g-4">
              {section.clients.map((client, index) => (
                <div key={index} className="col-6 col-sm-4 col-lg-2-4">
                  <div className="card h-100 border client-text-card">
                    <div className="card-body text-center d-flex align-items-center justify-content-center p-3">
                      <p className="mb-0 small fw-medium">{client}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .col-lg-2-4 {
          @media (min-width: 992px) {
            flex: 0 0 20%;
            max-width: 20%;
          }
        }
        
        .client-card {
          transition: transform 0.2s ease-in-out;
          background: white;
          border: 1px solid #dee2e6 !important;
        }
        
        .client-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        }
        
        .client-logo {
          max-height: 80px;
          object-fit: contain;
          width: 100%;
        }
        
        .client-text-card {
          transition: transform 0.2s ease-in-out;
          min-height: 80px;
          background: white;
          border: 1px solid #dee2e6 !important;
        }
        
        .client-text-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
          background-color: #f8f9fa;
        }
      `}</style>
    </div>
  )
}

