import React, { useState, useEffect } from "react"
import {Link} from "react-router-dom"
import video11 from '../assets/video11.mp4';
import video12 from '../assets/video12.mp4';
import video13 from '../assets/video13.mp4';
import video14 from '../assets/video14.mp4';
import video15 from '../assets/video15.mp4';
import video16 from '../assets/video16.mp4';
import video17 from '../assets/video17.mp4';

//Images
import Img1 from '../assets/gallery/Img1.jpeg';
import Img2 from '../assets/gallery/Img2.jpeg';
import Img3 from '../assets/gallery/Img3.jpeg';
import Img4 from '../assets/gallery/Img4.jpeg';
import Img5 from '../assets/gallery/Img5.jpeg';
import Img6 from '../assets/gallery/Img6.jpeg';
import Img7 from '../assets/gallery/Img7.jpeg';
import Img8 from '../assets/gallery/Img8.jpeg';
import Img9 from '../assets/gallery/Img9.jpeg';
import Img10 from '../assets/gallery/Img10.jpeg';
import Img11 from '../assets/gallery/Img11.jpeg';
import Img12 from '../assets/gallery/Img12.jpeg';
import Img13 from '../assets/gallery/Img13.jpeg';
import Img14 from '../assets/gallery/Img14.jpeg';
import Img15 from '../assets/gallery/img15.jpeg';
// Array of gallery images
const galleryMedia = [
  {
    id: 1,
    src: video11,
    alt: "Modern Building Facade",
    title: "Commercial Complex",
    type: "video"
  },
  {
    id: 2,
    src: video12,
    alt: "Residential Project",
    title: "Modern Residence",
    type: "video"
  },
  {
    id: 3,
    src: video13,
    alt: "Office Building",
    title: "Corporate Tower",
    type: "video"
  },
  {
    id: 4,
    src: video15,
    alt: "Industrial Project",
    title: "Industrial Complex",
    type: "video"
  },
  {
    id: 5,
    src: video14,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "video"
  },
  {
    id: 5,
    src: video16,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "video"
  },
  {
    id: 5,
    src: video17,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "video"
  }
];


//Images:
const imagesMedia = [
  {
    id: 1,
    src: Img1,
    alt: "Modern Building Facade",
    title: "Commercial Complex",
    type: "images"
  },
  {
    id: 2,
    src: Img2,
    alt: "Residential Project",
    title: "Modern Residence",
    type: "Images"
  },
  {
    id: 3,
    src: Img3,
    alt: "Office Building",
    title: "Corporate Tower",
    type: "Images"
  },
  {
    id: 4,
    src: Img4,
    alt: "Industrial Project",
    title: "Industrial Complex",
    type: "Images"
  },
  {
    id: 5,
    src: Img5,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "Images"
  },
  {
    id: 6,
    src: Img6,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "Images"
  },
  {
    id: 7,
    src: Img7,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "Images"
  },
  {
    id: 8,
    src: Img8,
    alt: "Modern Building Facade",
    title: "Commercial Complex",
    type: "images"
  },
  {
    id: 9,
    src: Img9,
    alt: "Residential Project",
    title: "Modern Residence",
    type: "images"
  },
  {
    id: 10,
    src: Img10,
    alt: "Office Building",
    title: "Corporate Tower",
    type: "Images"
  },
  {
    id: 11,
    src: Img11,
    alt: "Industrial Project",
    title: "Industrial Complex",
    type: "Images"
  },
  {
    id: 12,
    src: Img12,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "Images"
  },
  {
    id: 13,
    src: Img13,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "Images"
  },
  {
    id: 14,
    src: Img14,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "Images"
  },
  {
    id: 15,
    src: Img15,
    alt: "Shopping Mall",
    title: "Retail Space",
    type: "Images"
  }
];


export default function GalleryPage() {
  const [currentImage, setCurrentImage] = useState(0)

  // Auto-change image every 3 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % galleryMedia.length)
    }, 6000)

    return () => clearInterval(timer)
  }, [])

  // Function to manually change image
  const changeImage = (index) => {
    setCurrentImage(index)
  }

  return (
    <div className="min-vh-100 d-flex flex-column bg-light">
      {/* Hero Section */}
      <div
        className="hero-section position-relative py-3"
        style={{
          backgroundImage: 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))', 
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "250px", 

        }}
      >
        <div className="container text-center text-white py-3">
          <h1 className="display-4 fw-bold mb-3 text-center text-light">GALLERY</h1>
          <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Gallery
              </li>
            </ol>
          </nav>
        </div>
      </div>

     {/* Gallery Slideshow */}
<div className="container py-5">
  <div className="gallery-container">
    {/* Main Media Display (Image/Video) */}
    <div className="container-fluied bg-secondary text-center text-white">
    <h2>VIDEOS</h2>
    </div>
    <div className="main-image-container">
      {galleryMedia.map((media, index) => (
       <div key={media.id} className={`gallery-slide ${index === currentImage ? "active" : ""}`}>

          {media.type === "video" ? (
            <video controls className="main-media">
              <source src={media.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={media.src || "/placeholder.svg"} alt={media.alt} className="main-media" />
          )}
          <div className="media-caption">
            <h3>{media.title}</h3>
          </div>
        </div>
      ))}
  

            {/* Navigation Arrows */}
            <button
              className="nav-button prev"
              onClick={() => changeImage((currentImage - 1 + galleryMedia.length) % galleryMedia.length)}
            >
              ‹
            </button>
            <button className="nav-button next" onClick={() => changeImage((currentImage + 1) % galleryMedia.length)}>
              ›
            </button>

            {/* Navigation Dots */}
            <div className="navigation-dots">
              {galleryMedia.map((_, index) => (
                <button
                  key={index}
                  className={`dot ${index === currentImage ? "active" : ""}`}
                  onClick={() => changeImage(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>



{/* Images  Slideshow */}
<div className="container py-5">
  <div className="gallery-container">
    {/* Main Media Display (Image/Video) */}
    <div className="container-fluied bg-secondary text-center text-white">
    <h2>IMAGES</h2>
    </div>
    <div className="main-image-container">
      {imagesMedia.map((media, index) => (
       <div key={media.id} className={`gallery-slide ${index === currentImage ? "active" : ""}`}>

          {media.type === "video" ? (
            <video controls className="main-media">
              <source src={media.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={media.src || "/placeholder.svg"} alt={media.alt} className="main-media" />
          )}
          <div className="media-caption">
            <h3>{media.title}</h3>
          </div>
        </div>
      ))}
  

            {/* Navigation Arrows */}
            <button
              className="nav-button prev"
              onClick={() => changeImage((currentImage - 1 + imagesMedia.length) % imagesMedia.length)}
            >
              ‹
            </button>
            <button className="nav-button next" onClick={() => changeImage((currentImage + 1) % imagesMedia.length)}>
              ›
            </button>

            {/* Navigation Dots */}
            <div className="navigation-dots">
              {imagesMedia.map((_, index) => (
                <button
                  key={index}
                  className={`dot ${index === currentImage ? "active" : ""}`}
                  onClick={() => changeImage(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
      .main-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

        .gallery-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
        }

        .main-image-container {
          position: relative;
          width: 100%;
          height: 600px;
          overflow: hidden;
          border-radius: 8px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        .gallery-slide {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }

        .gallery-slide.active {
          opacity: 1;
        }

        .main-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .image-caption {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 20px;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
          color: white;
        }

        .image-caption h3 {
          margin: 0;
          font-size: 1.5rem;
        }

        .nav-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: #ff7c33;
          color: white;
          border: none;
          padding: 16px 24px;
          font-size: 24px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .nav-button:hover {
          background:rgb(77, 70, 62);
        }

        .prev {
          left: 0;
          border-radius: 0 4px 4px 0;
        }

        .next {
          right: 0;
          border-radius: 4px 0 0 4px;
        }

        .navigation-dots {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 8px;
          z-index: 2;
        }

        .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid white;
          background: transparent;
          padding: 0;
          cursor: pointer;
          transition: background-color 0.10s;
        }

        .dot.active {
          background: white;
        }

        @media (max-width: 768px) {
          .main-image-container {
            height: 400px;
          }

          .nav-button {
            padding: 12px 16px;
            font-size: 20px;
          }

          .image-caption h3 {
            font-size: 1.2rem;
          }

        }
      `}</style>
    </div>
  )
}

