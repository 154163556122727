import React from "react"
import c1 from '../assets/c1.jpg';
import c2 from '../assets/c2.jpg';
import c3 from '../assets/c3.jpg';
import c4 from '../assets/c4.jpg';
import c5 from '../assets/c5.jpg';
import c6 from '../assets/c6.jpg';
import c7 from '../assets/c7.jpg';
import c8 from '../assets/c8.jpg';
import c9 from '../assets/c9.png';
import c10 from '../assets/c10.png';
function Clients() {
  const clients = [
    {
      name: "TVS",
      logo: c1
    },
    {
      name: "antony",
      logo: c2
    },
    {
      name: "c3",
      logo: c3
    },
    {
      name: "c4",
      logo: c4
    },
    {
      name: "c5",
      logo: c5
    },
    {
      name: "c6",
      logo: c6
    },
    {
      name: "c7",
      logo: c7
    },
    {
      name: "c8",
      logo: c8
    },
    {
      name: "c9",
      logo: c9
    },
    ,
    {
      name: "c10",
      logo: c10
    },
    // Add more client logos
  ]

  return (
    <section className="clients-section py-5">
      <div className="container">
        <h2 className="text-center mb-5">OUR HONORABLE CLIENTS</h2>
        <div className="row row-cols-2 row-cols-md-4 g-4">
          {clients.map((client, index) => (
            <div key={index} className="col">
              <div className="client-logo p-3 bg-white shadow-sm rounded">
                <img src={client.logo || "/placeholder.svg"} alt={client.name} className="img-fluid" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Clients

