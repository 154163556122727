import { Link } from "react-router-dom"
import { Card } from "react-bootstrap"
import aboutpage from "../assets/aboutpage.jpeg"
import ab1 from "../assets/ab1.png"
import ab2 from "../assets/ab2.png"
import ab3 from "../assets/ab3.png"
import ab4 from "../assets/ab4.png"

export default function AboutPage() {
  const differentiators = [
    "On site R & D for Kinetic Facades",
    "Free Facade Consultation for Dynamic & Static Facades",
    "Personal Relationship with client",
    "100% on time handover of Kinetic Facade projects",
    "Collaboration with Architects for innovative Parametric Facade designs",
  ]

  return (
    <div className="min-vh-100 bg-light">
      {/* Hero Section */}
      <div
        className="relative h-[400px] bg-cover bg-center"
        style={{
          backgroundImage: "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "250px",
        }}
      >
        <div className="container position-relative d-flex flex-column justify-content-center align-items-center h-100">
          <h1 className="display-4 fw-bold mb-3 text-center text-white">ABOUT US - KINETIC FACADES MANUFACTURER</h1>
          <div className="d-flex align-items-center text-white-50">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About Us
              </li>
            </ol>
          </div>
        </div>
      </div>

      {/* Why Us Section */}
      <section className="py-5 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-4">
              <img
                src={aboutpage || "/placeholder.svg"}
                alt="Modern architectural Kinetic Facade building"
                className="img-fluid p-5"
              />
            </div>
            <div className="col-md-6">
              <h2 className="h3 mb-4">WHY CHOOSE OUR KINETIC FACADES?</h2>
              <p className="mb-4">
                In a market crowded with many Kinetic Facade manufacturers, we have created some distinctive
                differentiators as a leading Dynamic Facade manufacturer:
              </p>
              <ul className="list-unstyled">
                {differentiators.map((item, index) => (
                  <li key={index} className="d-flex align-items-center mb-3">
                    <span className="text-danger">›</span>
                    <span className="ms-2">{item}</span>
                  </li>
                ))}
              </ul>
              <p>
                It's not a wonder that with such focus & differentiators we have started creating not just amazing
                Kinetic Facades but also success stories as a premier Parametric Facade manufacturer.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Vision & Mission Section */}
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <Card className="shadow-lg">
                <Card.Body className="p-4">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h2 className="h3 mb-3">OUR VISION</h2>
                      <p>
                        To be the prime trustable Kinetic Facades manufacturer and steel constructions solution provider
                        to the different multitudes of industries with our best quality Half wave Kinetic systems and
                        timely deliveries at a competitive edge.
                      </p>
                    </div>
                    <img src={ab1 || "/placeholder.svg"} alt="Vision Icon" width={80} height={80} />
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-6 mb-4">
              <Card className="shadow-lg">
                <Card.Body className="p-4">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h2 className="h3 mb-3">OUR MISSION</h2>
                      <p>
                        Our mission is to develop ourselves as one of the best Full wave Kinetic Facade manufacturer and
                        construction partners in the industry. We continue to maintain the cost-effectiveness without
                        compromising the strength of the quality as per the Architect standards and requirements for
                        Kinetic Rope Facade system.
                      </p>
                    </div>
                    <img src={ab2 || "/placeholder.svg"} alt="Mission Icon" width={80} height={80} />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* Standards Section */}
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <Card className="shadow-lg">
                <Card.Body className="p-4">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h2 className="h3 mb-3">SAFETY, HEALTH & QUALITY</h2>
                      <p>
                        Safety, Health & Quality are our top priority as a Vertical Rotation Kinetic Facade
                        manufacturer. We believe building safe working environments using quality products and
                        stringently following safety practices are the key commitments we make to our clients in
                        delivering our Kinetic Facades services.
                      </p>
                    </div>
                    <img src={ab3 || "/placeholder.svg"} alt="Safety Icon" width={80} height={80} className="ml-4" />
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-6 mb-4">
              <Card className="shadow-lg">
                <Card.Body className="p-4">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h2 className="h3 mb-3">GLOBAL STANDARDS</h2>
                      <p>
                        As a leading Aluminium Kinetic Facade manufacturer, we comply with Global standards. Adopting
                        construction practices from most regions of the world. International standard quality products
                        and services are channelled through Global outsourcing facilities to fulfill international
                        standard materials and construction standards for Kinetic fixed Facade.
                      </p>
                      <Link
                        to="#"
                        className="btn btn-link text-danger "
                        style={{ textDecoration: "none", fontWeight: "900" }}
                      >
                        ISO Certification - Click Here
                      </Link>
                    </div>
                    <img
                      src={ab4 || "/placeholder.svg"}
                      alt="Global Standards Icon"
                      width={80}
                      height={80}
                      className="ml-4"
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* Hidden SEO Keywords Section */}
      <div className="d-none">
        <p>
          Kinetic Facades manufacturer, Dynamic Facade manufacturer, Static Facade manufacturer, Kinetic elevation,
          Kinetic Glazing, Kinetic marble elevation, Parametric Facade manufacturer, Kinetic louvers system, Half wave
          Kinetic systems, Full wave Kinetic Facade manufacturer, Kinetic Rope Facade system manufacturer, Vertical
          Rotation Kinetic Facade manufacturer, Kinetic fixed Facade manufacturer, Rose gold Kinetic Facade
          manufacturer, 4 wave Kinetic louvers system manufacturer, Automated Kinetic Facades manufacturer, Kinetic
          Facades perforation, Aluminium Kinetic Facade manufacturer, Kinetic elevation Facade manufacturer, Kinetic
          fluted Facade manufacturer,Luxury façades Manufacturer,  
Metal façades,  
Kinetic Horizontal pivot façades shading devices system,  
Kinetic vertical pivot façades shading devices system,  
Kinetic Horizontal foldings façades shading system,  
Shading,  
Premium kinetic ss façades Manufacturer,  
Premium metal façades Manufacturer,  
High quality Commercial kinetic façades Manufacturer in India,  
Premium Facade Manufacturer,Kinetic Facades motorized, Kinetic Facades ss mirror, Kinetic Facades embossed
          panel
        </p>
      </div>
    </div>
  )
}

