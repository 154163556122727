

import { useState } from "react";
import '../App.css';
import { Link } from "react-router-dom"
import b1 from "../assets/before/b1.jpg"
import a1 from "../assets/after/a1.jpeg"
import b2 from "../assets/before/b2.jpg"
import a2 from "../assets/after/a2.jpeg"
import b3 from "../assets/before/b3.jpg"
import a3 from "../assets/after/a3.jpeg"
import b4 from "../assets/before/b4.jpg"
import a4 from "../assets/after/a4.jpg"
import b5 from "../assets/before/b5.jpg"
import a5 from "../assets/after/a5.jpeg"
import b6 from "../assets/before/b6.jpg"
import a6 from "../assets/after/a6.jpg"
import b7 from "../assets/before/b7.jpeg"
import a7 from "../assets/after/a7.jpeg"
export default function ProjectsPage() {
  const [hoveredProjectId, setHoveredProjectId] = useState(null)

  const projects = [
    {
      id: 1,
      beforeImage: b1,
      afterImage: a1,
      beforeTitle: " Before",
      afterTitle: " After",
      category: "",
    },
    {
      id: 2,
      beforeImage: b2,
      afterImage: a2,
      beforeTitle: " Before",
      afterTitle: " After",
      category: "",
    },
    {
      id: 3,
      beforeImage: b3,
      afterImage: a3,
      beforeTitle: "Before",
      afterTitle: " After",
      category: "",
    },
    {
      id: 4,
      beforeImage: b4,
      afterImage: a4,
      beforeTitle: " Before",
      afterTitle: "After",
      category: "",
    },
    {
      id: 5,
      beforeImage: b5,
      afterImage: a5,
      beforeTitle: " Before",
      afterTitle: " After",
      category: "",
    },

    {
      id: 6,
      beforeImage: b6,
      afterImage: a6,
      beforeTitle: " Before",
      afterTitle: " After",
      category: "",
    },
    ,

    {
      id: 7,
      beforeImage: b7,
      afterImage: a7,
      beforeTitle: " Before",
      afterTitle: " After",
      category: "",
    },
  ]

  return (
    <div className="min-h-screen bg-gray-50">
      <div
        className="relative h-[250px] bg-cover bg-center bg-no-repeat flex items-center justify-center"
        style={{
          backgroundImage: "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height:'250px'
        }}
      >
        <div className="text-center">
          <h1 className="display-4 fw-bold mb-3 text-center text-light p-5">PROJECTS</h1>
          <div className="flex items-center justify-center gap-2 text-sm">
          <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Project
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16 mt-5">
        <div className="flex gap-8 overflow-x-auto whitespace-nowrap pb-4 scrollbar-hide">
          {projects.map((project) => {
            const isHovered = hoveredProjectId === project.id

            return (
              <div
                key={project.id}
                className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 flex-shrink-0 w-[300px]"
                onMouseEnter={() => setHoveredProjectId(project.id)}
                onMouseLeave={() => setHoveredProjectId(null)}
              >
                <div className="relative w-[300px] h-[300px] overflow-hidden imgb">
                  <img
                    src={isHovered ? project.afterImage : project.beforeImage}
                    alt={isHovered ? project.afterTitle : project.beforeTitle}
                    className="w-full h-full object-cover transition-all duration-500 ease-in-out"
                  />
                  <div className="absolute bottom-4 left-4 bg-black/70 text-white px-3 py-1 text-sm rounded">
                    {isHovered ? "After" : "Before"}
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 transition-all duration-500">
                    {isHovered ? project.afterTitle : project.beforeTitle}
                  </h3>
                  <p className="text-gray-600">{project.category}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

