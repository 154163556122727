
import profile from '../assets/profile.jpg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Profile() {
  return (
    <div className="container py-5">
      {/* Profile Section */}
      <section className="row align-items-center justify-content-center">
        
        {/* Left Side: Centered Circular Image with Name */}
        <div className="col-md-4 text-center mb-4 mb-md-0 leftimg ">
          <div className="profile-img-container mt-5">
            <img
              src={profile}
              alt="Profile"
              className="profile-img"
            />
          </div>
          <div className="profile-name">Founder</div>
        </div>

        {/* Right Side: About Section with Proper Underline */}
        <div className="col-md-8 text-center text-md-start rigthdiv">
          <div className="about-heading">
            <h2 className="fw-bold text-dark">About</h2>
            <div className="underline"></div> {/* Underline properly aligned */}
          </div>
          <p className="text-secondary about-text">
            We boomed in the year of 2007 by professionals to meet the modernized  Architectural Facades Fabrication 
            assignments. From the inception of this company, lots of esteemed projects were completed with a professional
            approach and teamwork. We work in coordination with our architect and builders to provide them with a
            product that is in line with their business objectives and that the products meet their expectations. 
            ,we have overall 
            responsibility for the execution of work on time.
          </p>
        </div>
      </section>
    </div>
  );
}
