import "bootstrap/dist/css/bootstrap.min.css"
import "../testimonials.css"
import gv1 from "../assets/inform/gv1.mp4"
import gv2 from "../assets/inform/gv2.mp4"
import v1 from "../assets/inform/v1.mp4"
import v2 from "../assets/inform/v2.mp4"
import v3 from "../assets/inform/v3.mp4"
import v4 from "../assets/inform/v4.mp4"
import v5 from "../assets/inform/v5.mp4"
const TestimonialsPage = () => {
  // First row videos (2 videos)
  const firstRowVideos = [
    {
      id: 1,
      url: gv1,
      duration: "1:21",
    },
    {
      id: 2,
      url:gv2,
      duration: "3:24",
    },
  ]

  // Second row videos (3 videos)
  const secondRowVideos = [
    {
      id: 3,
      url:v1,
      duration: "0:32",
    },
    {
      id: 4,
      url:v2,
      duration: "0:55",
    },
    {
      id: 5,
      url:v3,
      duration: "0:36",
    },
   
    {
      id: 6,
      url:v4,
      duration: "0:36",
    },

    {
      id: 7,
      url:v5,
      duration: "0:36",
    },
  ]

  return (
    <div className="testimonials-container">
      {/* Header Section */}
      <div className="header-section" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/header-bg.jpg)` }}>
        <div className="overlay">
          <h1 className="display-4 fw-bold mb-3 text-center text-light">TESTIMONIALS</h1>
          <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Testimonials
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="container py-5">
        <div className="section-title">
          <h2 className="testimonials-heading">TESTIMONIALS</h2>
        </div>

        {/* First Row - 2 Videos */}
        <div className="row g-4 mb-5">
          {firstRowVideos.map((video) => (
            <div key={video.id} className="col-md-6">
              <div className="video-card">
                <div className="video-wrapper">
                  <iframe
                    src={video.url}
                    title={`Testimonial ${video.id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="duration-badge">{video.duration}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Second Row - 3 Videos */}
        <div className="row g-4">
          {secondRowVideos.map((video) => (
            <div key={video.id} className="col-md-4">
              <div className="video-card">
                <div className="video-wrapper">
                  <iframe
                    src={video.url}
                    title={`Testimonial ${video.id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="duration-badge">{video.duration}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TestimonialsPage

