

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Navbar from "./components/Navbar"
import ScrollToTop from "./components/ScrollToTop"
import Hero from "./components/Hero"
import Services from "./components/Services"
import Stats from "./components/Stats"
import Clients from "./components/Clients"
import Footer from "./components/Footer"
import About from "./components/About"
import ServicePage from "./components/ServicePage"
import Project from "./components/Project"
import ClientPage from "./components/ClientPage"
import Gallery from "./components/Gallery"
import Contact from "./components/Contact"
import Profile from "./components/Profile"
import TestimonialPage from "./components/TestimonialPage"
import SEOLandingPage from "./components/SEOLandingPage" // Import the new SEO landing page
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Add this to ensure all page navigations start at the top */}
      <div className="app">
        <Navbar /> {/* Navbar with links */}
        <Routes>
          {/* Home Page */}
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Profile />
                <Stats />
                <Services />
                <Clients />
              </>
            }
          />

          {/* About Page */}
          <Route path="/about" element={<About />} />
          <Route path="/ServicePage" element={<ServicePage />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/ClientPage" element={<ClientPage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/testmonial" element={<TestimonialPage />} />
          <Route path="/contact" element={<Contact />} />

          {/* New SEO Landing Pages */}
          <Route path="/kinetic-facades" element={<SEOLandingPage />} />
          <Route path="/dynamic-facade-manufacturer" element={<SEOLandingPage />} />
          <Route path="/static-facade-manufacturer" element={<SEOLandingPage />} />
          <Route path="/parametric-facade-manufacturer" element={<SEOLandingPage />} />
        </Routes>
        <Footer /> {/* Footer on all pages */}
      </div>
    </Router>
  )
}

export default App

