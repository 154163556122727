"use client"
import "bootstrap/dist/css/bootstrap.min.css"

const services = [
  {
    id: 1,
    title: "STRUCTURAL GLAZING WITH KINETIC ELEMENTS",
    description:
      "Our Kinetic Glazing solutions are most suitable for high-rise buildings requiring huge quantity of glazing. Initially, a detailed site survey is conducted. Based on that, MS/SS/Aluminium brackets are anchored to the columns/slabs.",
    additionalInfo:
      "This system does not require scaffolding for installation. Installation of the Kinetic Facade panels is done using a crane/cradle/hoist. 90% of the work is done at the factory and only 10% of the task is done on-site.",
  },
  {
    id: 2,
    title: "DYNAMIC FACADE SPIDER GLAZING",
    description:
      "As a Dynamic Facade manufacturer, our spider glazing offers a beautiful external appearance with uninterrupted views. Spider glazing curtain walls provides maximum daylight possible to the building interiors. They also provide large transparent glass surface as building envelopes.",
    additionalInfo:
      "This acts as a structural support to the exterior designs and spider glazing can be customized for buildings of any design concepts. This is highly suitable for both commercial and residential buildings.",
  },
  {
    id: 3,
    title: "KINETIC STEEL ROOFING / METAL ROOFING",
    description:
      'Our Kinetic Facades include steel roofing solutions. The term "tin roofing" is used interchangeably with metal roofing, steel roofing, or galvanized steel, all featuring kinetic elements for improved energy efficiency.',
    additionalInfo:
      "In fact, tin is actually a rarely used type of metal for roofing. Tin itself is an element, like Copper or Zinc, but our Kinetic Facade systems utilize more durable materials.",
  },
  {
    id: 4,
    title: "PARAMETRIC FACADE SHOP GLAZING",
    description:
      "As a Parametric Facade manufacturer, our shop glazing solutions feature large glass panels that are erected to give a wonderful view of shops interiors. The shop glazed interiors help displaying products to the prospective buyers outside.",
    additionalInfo:
      "Advantages of Shop Glazing:\n• Provides a clear view of the interior enabling display of products and services.\n• The shop glazed glasses are thick and strong and do not shatter that easily on any possible impact.",
  },
  {
    id: 5,
    title: "KINETIC SKYLIGHT GLAZING",
    description:
      "Our Kinetic Facades include skylight solutions - a light-transmitting structure that forms all or part of the roof space of a building for daylighting purposes. They are used as daylighting elements to allow direct and/or indirect sunlight, via top lighting to the building interiors.",
    additionalInfo:
      "They provide a visual connection to the outdoor environment to interior occupants. With skylight, sustainable building is possible that allows for passive solar heating, passive cooling and fresh air exchange.",
  },
  {
    id: 6,
    title: "KINETIC CANOPY SYSTEMS",
    description:
      "Our Kinetic Facades include canopy systems that typically incorporate a fabric, glass or metal covering. They may be translucent, transparent or opaque. A canopy is an overhead roof structure that has open sides with kinetic elements.",
    additionalInfo:
      "Canopies are typically intended to provide shelter from the rain or sun, but may also be used for decorative purposes. They can be customized to take load as well if needed.",
  },
  {
    id: 7,
    title: "ALUMINIUM KINETIC FACADE CLADDING (ACP)",
    description:
      "As an Aluminium Kinetic Facade manufacturer, we create composite panels that are popular materials of modern architecture that are lightweight and highly flexible. This is used for both exteriors and interiors of buildings. ACP sheets are available in a wide range of colours and finishes like 3D, sand, wood, stone etc.",
    additionalInfo:
      "ACP sheets can be bent and folded into whatever shape you wish which is not possible by other materials. This flexibly of ACP provides enhanced design liberty and is a designers' delight.",
  },
  {
    id: 8,
    title: "HIGH PRESSURE LAMINATE KINETIC CLADDING (HPL)",
    description:
      "Our Kinetic Facades include high pressure laminate panels that provide beautiful aesthetic and a wide range of design possibilities. This popular material of modern era is highly suitable for next generation architectural claddings.",
    additionalInfo:
      "This is a high quality durable exterior wall cladding product. Apart from design and style, HPL panels are functional and energy efficient. They are sturdy and solid, weather resistant and the colours are long lasting.",
  },
  {
    id: 9,
    title: "WOODEN PLASTIC COMPOSITE KINETIC FACADES",
    description:
      "Our Kinetic Facades include wooden plastic composite materials made from a unique blend of natural wood and plastic fibres. WPC can be created from entirely recycled materials. It comprises of sawdust, pulp, bamboo, and plastic powder.",
    additionalInfo:
      "As WPC is created from a paste like substance, it can be moulded into any size and shape. In addition to this innate flexibility, WPC can be dyed into any colour suitable for any design scheme.",
  },
]

export default function ServicePage() {
  return (
    <div className="min-vh-100 bg-light">
      {/* Hero Section */}
      <div
        className="hero-section position-relative py-3"
        style={{
          backgroundImage: "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "250px",
        }}
      >
        <div className="container py-4">
          <h1 className="display-4 fw-bold mb-3 text-center text-light">KINETIC FACADES SERVICES</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Service
              </li>
            </ol>
          </nav>
          <p className="lead mx-auto text-light text-center" style={{ maxWidth: "800px", fontWeight: "600  " }}>
            We provide our customers with outstanding levels of customer service as a leading Kinetic Facades
            manufacturer, specializing in Dynamic Facade and Static Facade solutions.
          </p>
        </div>
      </div>

      {/* Services Section */}
      <section className="py-5">
        <div className="container">
          {services.map((service, index) => (
            <div
              key={service.id}
              className={`row service-item align-items-center mb-5 ${index % 2 === 0 ? "" : "flex-row-reverse"}`}
            >
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="service-number">{service.id}</div>
                <h3 className="mb-4">{service.title}</h3>
              </div>
              <div className="col-lg-6 " style={{ height: "400px", padding: "80px", backgroundColor: "#565656" }}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-light p-4" style={{ backgroundColor: "#565656" }}>
                    <p className="card-text ">{service.description}</p>
                    <p className="card-text small mb-0">{service.additionalInfo}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Hidden SEO Keywords */}
        <div className="d-none">
          <p>
            Kinetic Facades manufacturer, Dynamic Facade manufacturer, Static Facade manufacturer, Kinetic elevation,
            Kinetic Glazing, Kinetic marble elevation, Parametric Facade manufacturer, Kinetic louvers system, Half wave
            Kinetic systems, Full wave Kinetic Facade manufacturer, Kinetic Rope Facade system manufacturer, Vertical
            Rotation Kinetic Facade manufacturer, Kinetic fixed Facade manufacturer, Rose gold Kinetic Facade
            manufacturer, 4 wave Kinetic louvers system manufacturer, Automated Kinetic Facades manufacturer, Kinetic
            Facades perforation, Aluminium Kinetic Facade manufacturer, Kinetic elevation Facade manufacturer, Kinetic
            fluted Facade manufacturer, Kinetic Facades motorized, Kinetic Facades ss mirror, Kinetic Facades embossed
            panel
          </p>
        </div>
      </section>

      <style jsx>{`
        .service-item {
          position: relative;
          overflow: hidden;
        }

        .service-number {
          display: inline-block;
          width: 50px;
          height: 50px;
          background: #ffa500;
          color: white;
          text-align: center;
          line-height: 50px;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 1rem;
        }

        .service-item:hover .service-number {
          transform: scale(1.1);
          transition: transform 0.3s ease;
        }

        @media (max-width: 991.98px) {
          .service-number {
            width: 40px;
            height: 40px;
            font-size: 20px;
            line-height: 40px;
          }
        }
      `}</style>
    </div>
  )
}

