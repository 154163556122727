"use client"

import { useState, useEffect } from "react"
import styles from "../Hero.module.css"

import homev11 from "../assets/homev11.mp4"
import homev12 from "../assets/homev12.mp4"
import homev13 from "../assets/homev13.mp4"
import homev14 from "../assets/homev14.mp4"
import homev15 from "../assets/homev15.mp4"
import homev16 from "../assets/homev16.mp4"

const videos = [homev16, homev15, homev14, homev13, homev12, homev11]

function Hero() {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length)
    }, 5000) // Change video every 5 seconds

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={styles.heroSection}>
      <video className={styles.backgroundVideo} autoPlay loop muted playsInline key={videos[currentVideoIndex]}>
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.heroOverlay}></div>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>ARCHITECTURAL KINETIC FACADE MANUFACTURER</h1>
        <p className={styles.heroSubtitle}>
          <ul>
            <li>Half Wave Kinetic System</li>
            <li>Full Wave Kinetic Facade</li>
            <li>Kinetic Rope Facade System</li>
            <li>Static & Dynamic Facade</li>
            <li>Parametric Facade System</li>
            <li>Automated Kinetic Facades</li>
            <li>Aluminium Kinetic Facade</li>
            <li>Premium Facade Manufacturer</li>
          </ul>
        </p>
        <div className={styles.heroKeywords}>
          <p className="d-none">
            Kinetic Facades manufacturer, Premium Facade Manufacturer,ynamic Facade manufacturer, Static Facade manufacturer, Kinetic elevation,
            Kinetic Glazing, Parametric Facade manufacturer, Kinetic louvers system, Vertical Rotation Kinetic Facade
            manufacturer, 4 wave Kinetic louvers system manufacturer
          </p>
        </div>
      </div>
    </div>
  )
}

export default Hero

