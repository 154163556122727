"use client"

import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import Logo from "../assets/Logo.png"
import pdf1 from "../assets/inform/inform.pdf"
import pdf2 from "../assets/inform/inform2.pdf"
import "../components/Navbar.css" // We'll create this CSS file for custom styling

function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  // Handle mobile menu toggle
  const toggleMenu = () => {
    setIsOpen(!isOpen)
    // Prevent body scrolling when menu is open
    if (!isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbar = document.getElementById("navbarNav")
      const toggleButton = document.querySelector(".navbar-toggler")

      if (isOpen && navbar && !navbar.contains(event.target) && !toggleButton.contains(event.target)) {
        setIsOpen(false)
        document.body.style.overflow = "auto"
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  // Scroll to top when route changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  // Close mobile menu when a link is clicked
  const handleLinkClick = () => {
    if (isOpen) {
      setIsOpen(false)
      document.body.style.overflow = "auto"
    }
    window.scrollTo(0, 0)
  }

  // Handle PDF download
  const handleDownload = () => {
    const downloadFile = (file, fileName, delay) => {
      setTimeout(() => {
        const link = document.createElement("a")
        link.href = file
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, delay)
    }

    // Download both PDFs with a small delay
    downloadFile(pdf1, "information1.pdf", 0)
    downloadFile(pdf2, "information2.pdf", 100) // 100ms delay
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light  sticky-top shadow-sm">
      <div className="container">
        <Link className="navbar-brand" to="/" onClick={handleLinkClick}>
          <img
            src={Logo || "/placeholder.svg"}
            alt="Global Innovation Logo"
            height="120"
            width="200"
            loading="eager" // Load logo eagerly for better UX
          />
        </Link>
        <button
          className={`navbar-toggler ${isOpen ? "collapsed" : ""}`}
          type="button"
          onClick={toggleMenu}
          aria-expanded={isOpen ? "true" : "false"}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarNav">
          <div className="mobile-menu-overlay" onClick={toggleMenu}></div>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={handleLinkClick}>
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" onClick={handleLinkClick}>
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/ServicePage" onClick={handleLinkClick}>
                SERVICES
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Project" onClick={handleLinkClick}>
                PROJECTS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/ClientPage" onClick={handleLinkClick}>
                CLIENTS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gallery" onClick={handleLinkClick}>
                GALLERY
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/testmonial" onClick={handleLinkClick}>
                TESTIMONIAL
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={handleLinkClick}>
                CONTACT
              </Link>
            </li>
        
           
            <li className="nav-item mx-1">
              <button onClick={handleDownload} className="download-btn" aria-label="Download Information">
                Download
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

